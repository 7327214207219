@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

body {
  font-family: "Inter", sans-serif !important;
}

.deleteButton {
  display: flex;
  justify-content: flex-end;
}

.gradient-custom-2 {
  /* fallback for old browsers */
  background: #fccb90;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(
    to right,
    #ee7724,
    #d8363a,
    #dd3675,
    #b44593
  );

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);
}

ul.flex.flex-col.space-y-4.text-\[14px\].font-medium.text-gray-500 {
  padding-left: 0px;
}

.AddCardButton {
  display: flex;
  justify-content: flex-end;
}

.flex-grow-70 {
  flex-grow: 0.8;
}

.week-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
}

.card-index {
  position: absolute;
  width: 213px;
  z-index: 99;
  top: 3rem;
  /* left: -1rem; */
}

.week-selector button {
  padding: 5px 10px;
}

.bookingButton {
  background-color: #ed1c25;
  border-radius: 8px;
}

.week-selector h2 {
  margin: 0;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.rounded-lg.day-pitch.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  border-right: 1px solid #d6d5d5;
}

.headingBoxStyle {
  width: 150px;
  height: 40px;
  border: 1px solid;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  border-color: #ed1c25;
  color: #ed1c25;
}

.headingBoxStyle.arrow-left,
.headingBoxStyle.arrow-right,
.headingBoxStyle.arw-left,
.headingBoxStyle.arw-rght {
  width: 30px;
  border-color: #d6d5d5;
  color: #d6d5d5;
  font-weight: 800;
}

.pitch-box-style {
  width: 150px;
  height: 40px;
  border: 1px solid;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  border-color: #48c14c;
  color: #48c14c;
  /* padding-left: 1%;
      padding-right: 1%; */
}

.empty-div {
  border: 1px solid gray;
  background: #f7f7f7;
  color: black;
  border-radius: 10px;
  text-align: center;
  font-weight: 600;
  font-size: 22px;
}

.pitch-week-box-style {
  margin: 2px;
  border: 2px solid #ccc;
  border-radius: 4px;
  padding: 2px;
  width: 48px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  border-color: #48c14c;
  color: #48c14c;
}

.booking-btns {
  border: 1px solid darkgray;
  padding: 20px;
  background: #f7f7f7;
  border-radius: 10px;
}

.booking-avaiable {
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  color: #322d2d;
}

.item-booking {
  font-size: 15px;
}

.time-slot {
  font-size: 14px;
  color: #ed1c25;
  font-weight: 600;
}

.download-invoice {
  margin-bottom: 2rem;
  width: 375px;
  border-radius: 8px;
  background-color: #ed1c25;
  color: #fff;
}

.payment-successfull-page {
  width: 100%;
  height: 100vh;
}

.payment-successfull-box {
  width: 455px;
  height: 368px;
  border-radius: 16px;
  border-width: 1px;
  border: 1px solid #d6d5d5;
}

.boxStyleWeeks {
  width: 150px;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.timings {
  width: 150px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  margin-bottom: 10px;
  margin-top: 5px;
  color: black;
}

.pitch-name-week {
  font-size: x-small;
}

.timings-week {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  margin-top: 1px;
  color: black;
  height: 140.6px;
}

.nav-links span {
  cursor: pointer;
  transition: all 0.6s ease;
}

.nav-links span:hover {
  color: #e82026;
}

.signedInNav {
  display: none;
}

.nav-logo {
  cursor: pointer;
}

.app-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.signup-form-container {
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: top;
}

.signup-form {
  width: 80%;
  max-width: 400px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.image-container {
  flex: 6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
}

.image-container img {
  width: 100%;
  object-fit: cover;
}

.googleSignup {
  background: #f8f8f8;
  color: #9c9c9c;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
  letter-spacing: 0.05em;
  text-align: left;
}

.verify_btn {
  padding: 14px;
  font-size: 12px;
}

.signin-btn p {
  font-size: 17px;
}

.footer-logo img {
  height: 4rem !important;
}

.password-toggle {
  position: absolute;
  margin: 0 10px 0 0;
}

.password-toggle svg.lucide.lucide-eye {
  color: #9c9a9a;
}

.signUpLogo {
  cursor: pointer;
}

.newsletterSubmit {
  padding: 6px 6px 6px 24px;
  border: 1px solid #a5000780 !important;
}

.newsletterSubmit .icon-input {
}

.newsletterSubmit button {
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: center;
}

.footer-bottom {
  padding-top: 40px;
}

.footer-bottom h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  border-bottom: 2px solid #f6e0e1;
  padding: 0 0 10px 0;
}

.footer-bottom ul {
  padding: 0;
  margin: 0 !important;
}

.footer-bottom ul li {
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
  padding: 0;
  margin: 0 !important;
}

.footer-bottom ul li a {
  text-decoration: none;
  color: #5b5757;
}

.social-media {
  margin: 0 !important;
}

.social-media a {
  color: #000 !important;
}

.social-media a svg {
  height: 17px;
}

.footer-copyright {
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  justify-content: space-between;
  color: #9c9c9c;
}

nav.navbar {
  box-shadow: 0px 0px 6px 0px #00000029;
  background: transparent;
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.booking-for {
  margin-bottom: 1rem;
}

.nav-toggle {
  height: 48px;
  border-radius: 30px;
  border: 1px solid #d6d5d5;
  align-items: center;
  justify-content: center;
  padding: 8px 9px;
  column-gap: 15px;
  cursor: pointer;
  transition: all 0.6s ease;
}

.nav-toggle:hover {
  opacity: 0.7;
}

.nav-toggle .toggle-bars {
  font-size: 16px;
  padding-left: 10px;
}

.nav-toggle .nav-profile-pic {
  height: 32px;
  width: 32px;
}

.notification {
  width: 25px;
  height: auto;
  cursor: pointer;
  margin-left: 20px;
  transition: all 0.6s ease;
}

.notification:hover {
  opacity: 0.7;
}

.searchBarContent label {
  font-size: 14px;
  font-weight: 500;
  line-height: 20.4px;
  color: #322d2d;
}

.searchBarContent1 input {
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  color: #494848;
  width: 88px;
}

.searchBarContent .searchBtn {
  cursor: pointer;
}

.venue-card .ant-card-body {
  padding: 16px 18px;
  max-height: 90px;
}

.venue-card .ant-card-cover {
  display: flex;
  height: 250px;
  min-height: 115px;
}

.venue_img {
  display: flex;
  height: 100%;
}

.absolute.wishlist {
  right: 10px;
  top: 10px;
}

.venue-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 20.4px;
}

.venue-detail {
  font-size: 12px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
}

.venueDetailsHeading {
  font-size: 26px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
}

.bookingButton {
  height: 45px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.venue-content h2 {
}

.venue-content h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  color: #322d2d;
}

.venue-contact li {
  display: flex;
  align-items: center;
  margin: 7px 0 7px 0;
  column-gap: 15px;
  font-size: 16px;
  font-weight: 400;
}

.venue-contact li img {
  height: 20px;
}

.venue-boxes img {
  width: 20px;
  height: 20px;
}

.venue-boxes h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #322d2d;
}

.venue-boxes p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #322d2d;
}

.pitch-name-address {
  font-size: 26px;
  font-weight: 600;
  line-height: 30px;
}

.select-box {
  border-radius: 10px !important;
}

.MuiInputBase-input input {
  border: 0 !important;
}

label + .MuiInputBase-root-MuiInput-root {
  margin-top: 10px !important;
}

.cartBtn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #ed1c25 !important;
  color: #ffff !important;
  border: #ed1c25 !important;
  border-radius: 0;
  font-size: 18px;
  font-weight: 500;
  height: 50px;
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.cancellation-pol h3 {
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.22px;
  color: #322d2d;
  padding: 0 0 24px 0;
  border-bottom: 1px solid #d6d5d5;
}

.cancellation-pol h3 span {
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #322d2d;
}

.cancellation-pol p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #5b5757;
}

.cartBtn span {
}

.cart-popup {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  height: 100vh;
}

.cart .close-popup {
  display: none;
}

.booking-for .MuiFormControl-root,
.pitches-shown .MuiFormControl-root {
  margin-left: 0;
}

/* Styles for open cart in mobile view */
@media (max-width: 768px) {
  .venue-card .ant-card-cover {
    height: auto;
  }

  .cart {
    display: none;
  }

  .cart.mobile-open {
    /* Styles to make the cart visible in mobile view when it's open */
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    background: #fff;
    height: 100%;
    width: 100%;
    padding: 20px;
  }

  .search-icon-btn {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media (min-width: 768px) {
  .gradient-form {
    height: 100vh !important;
  }

  .navbar .rightLinks {
    flex-direction: row !important;
  }

  /* .footer-logo img{
    height: 24px !important;
  } */

  .footer-logo {
    font-size: 14px;
    color: #5b5757;
    line-height: 23px;
    margin-top: -40px;
  }
}

@media (min-width: 769px) {
  .gradient-custom-2 {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
}

@media (max-width: 1440px) {
  .venue-card .ant-card-cover {
    height: 180px;
  }
}

@media (max-width: 1024px) {
  .venue-card .ant-card-cover {
    min-height: 115px;
    height: auto;
  }
}

@media (max-width: 769px) {
  .signup-form-container {
    padding: 20px;
  }

  .form-body {
    padding: 0;
  }

  .image-container {
    display: none;
  }

  nav.navbar {
    padding: 10px 20px !important;
  }

  .cart-div {
    display: none;
  }
}

@media (max-width: 426px) {
  .searchbarFilter {
    display: flex;
    flex-direction: column;
    margin-left: 45px;
  }

  .nearMeSports {
    display: flex;
    justify-content: center;
    margin: auto;
  }

  .searchBar {
    width: 98%;
    height: 60px;
  }

  .venueSearch {
    padding-right: 0px;
    font-size: 12px;
    padding-left: 0;
  }

  .pitch-day {
    width: 100px;
  }

  .activitySearch {
    font-size: 12px;
    border: none;
  }

  .searchButton {
    display: none;
  }

  .searchBarContent {
    padding: 0;
  }

  .newsLetter {
    display: block;
  }

  .header-section {
    padding: 1rem;
  }

  .venueDetailsHeading {
    margin-left: 0.5rem;
    font-size: 15px;
    padding-bottom: 0px;
  }

  .bookingButton {
    margin-right: 0;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    font-size: small;
    font-weight: 400;
  }

  .payment-successfull-page {
    width: 100%;
    height: 50vh;
  }

  .payment-successfull-box {
    width: 387px;
    height: 356px;
    border-radius: 16px;
    border-width: 1px;
  }

  .download-invoice {
    margin-bottom: 1rem;
    width: 317px;
    border-radius: 8px;
  }

  .pitches {
    overflow: auto;
    width: 100%;
  }

  .pitch-booking-section {
    padding-right: 0px;
  }

  .checkout-btn,
  .view-cart-button {
    margin-right: 3rem;
  }

  .timing {
    width: 80px;
  }

  .pitch-day {
    margin-left: 1rem;
  }

  .pitch-box-style {
    width: 71px;
    height: 40px;
    border: 1px solid;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    border-color: #48c14c;
    color: #48c14c;
  }

  .venue-content h2 {
    font-size: 18px;
  }

  .venue-boxes h4 {
    font-size: 14px;
    font-weight: 700;
  }

  .venue-boxes p {
    font-size: 14px;
  }

  .venue-content p {
    font-size: 15px;
  }

  .headingBoxStyle {
    width: 71px;
    height: 40px;
    border: 1px solid;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    border-color: #ed1c25;
    color: #ed1c25;
  }

  .timings {
    width: 90px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 7px 5px 11px;
    color: black;
  }

  .data-picker {
    margin-top: 0.5rem;
    width: 150px !important;
    margin-left: 0px !important;
  }

  .data-picker-week {
    margin-left: -1rem;
    margin-top: 0.5rem;
  }

  .pitch-name-address {
    margin-left: 1rem;
    margin-top: 1rem;
    font-size: 15px;
  }

  .day-week-btn {
    overflow: visible !important;
  }

  .pitch-day {
    margin-left: 1rem;
  }

  .booking-for {
    margin-left: 1rem;
  }

  .view-cart-button {
    font-size: 12px;
  }

  .pitches-shown {
    margin-left: 1rem;
  }

  .day-pitch,
  .week-pitch {
    font-size: 12px !important;
    padding: 5px !important;
    min-width: 72px !important;
  }

  .week-range {
    font-size: 8px;
  }

  .date-tabs {
    margin-top: 0.5rem;
  }

  .week-calender {
    margin-left: 1rem;
    overflow: auto;
  }

  .time-col-week {
    width: 42px;
  }

  .verify-signup {
    margin-bottom: 1rem;
  }

  .timings-week {
    width: 51px;
    height: 140.6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1px 5px 0px;
    color: black;
    font-size: x-small;
  }

  .venue-info {
    margin-left: 1rem;
    margin-top: 0.2rem;
  }

  .venue-contact {
    margin-top: 0rem;
    margin-left: 1rem;
    padding-top: 1rem;
  }

  .venue-details {
    font-size: 14px;
  }

  .pitch-booking-section {
    padding-left: 0px !important;
  }

  .account-detail {
    width: 8rem;
  }

  .footer-logo {
    font-size: 13px;
  }

  .text-p {
    font-size: 16px;
  }

  .newsLetter {
    margin-top: 1rem;
  }

  .signin {
    font-size: 18px;
    padding-top: 1rem;
  }

  .newsletter-head,
  .newsfoot {
    font-size: 12px;
  }

  .venue-anchor {
    font-size: 15px;
  }

  .venue-available-for {
    font-size: 12px;
    margin-bottom: 0px;
  }

  .available-venue-spec {
    font-size: small;
  }

  .ant-col.p-4.venue-cards.ant-col-xs-12.ant-col-sm-6.css-dev-only-do-not-override-usln0u {
    padding: 0.5rem !important;
  }

  .searchBar-nav {
    display: none;
  }

  .account-page {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pitch-week {
    min-width: 72px !important;
  }
  /* .fly-cart{
    position: relative;
    top: auto;
    left: -11rem;
  } */

  .week-calender {
    width: 100%;
  }

  .week-pitch {
    width: 100px;
  }

  .searchBarContent label {
    font-size: 12px;
    font-weight: 500;
    line-height: 20.4px;
    color: #322d2d;
  }

  .footer-bottom-end {
    display: flex;
    flex-direction: column;
  }

  .book-section {
    padding-left: 0px !important;
  }
  .week-internal {
    width: 100%;
  }

  .week-internal-section {
    width: 370px;
  }

  .day-week-tab {
    margin-right: 0rem !important;
  }

  .booking-btns {
    display: flex;
    flex-direction: column;
  }

  .book-text {
    font-size: 30px;
  }

  .booking-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;
  }
  .item-booking {
    font-size: 11px;
  }

  .bookingButton {
    font-size: 10px;
  }
}

@media (max-width: 376px) {
  .navbar {
    width: auto;
  }

  .activitySearch {
    font-size: 9px;
  }

  .venueSearch {
    font-size: 9px;
  }

  .price-details {
    border-radius: 16px;
    border: 1px solid #d6d5d5;
    margin-left: 0;
  }

  .paymentButton {
    width: 246px;
    background-color: #ed1c25;
    color: #fff;
    border-radius: 8px;
  }

  .download-invoice {
    margin-bottom: 1rem;
    width: 272px;
    border-radius: 8px;
  }

  .payment-successfull-box {
    width: 343px;
    height: 340px;
    border-radius: 16px;
    border-width: 1px;
  }

  .payment-successfull-page {
    width: 100%;
    height: 48vh;
  }

  button.bg-gray-500.hover\:bg-gray-700.text-white.font-bold.py-2.px-4.sm\:px-2.rounded.mr-20.view-cart-button {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .date-tabs {
    flex-direction: row;
    align-items: flex-start;
  }
}

@media (max-width: 320px) {
  .navbar {
    width: auto;
  }
}
